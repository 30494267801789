.my-work {
    background: radial-gradient(circle, #353535 10%, transparent 11%);
    background-size: 1em 1em;
    background-color: #181818;
    color: var(--clr-light);
    display: flex;
    flex-flow: row wrap;
    align-items: start;
    position: relative;
    min-height: 100vh;
    width: 100vw;
}

.titles--work {
    width: 80%;
    margin: 0 auto;
}

.section__title--work {
    text-align: left;
}

.section__subtitle--work {
    width: 100%;
}

.portfolio {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.portfolio__item {
    position: relative;
    width: 100%;
    height: 10em;
    object-fit: cover;
    background: var(--clr-dark);
    overflow: hidden;
}

.portfolio__img {
    position: relative;
    transition: 
        transform 750ms cubic-bezier(.5, 0, .5, 1),
        opacity 250ms linear;
    z-index: 1;
}

.portfolio__item:focus {
    position: relative;
}

.hover-text {
    position: absolute;
    background: var(--clr-dark);
    margin: 2em;
    width: 20em;
    color: var(--clr-accent);
    font-size: 0.8rem;
}

.portfolio__img:hover,
.portfolio__item:focus .portfolio__img {
    transform: scale(1.2);
    opacity: 0.1;
}

@media (min-width: 600px) and (orientation: landscape) {
    .portfolio {
        width: 80%;
    }

    .portfolio__item {
        height: 11.5em;
    }

}

@media (min-width: 820px) {
    .portfolio__item {
        height: 9.3em;
    }
}