* {
    margin: 0;
    box-sizing: border-box;
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3em;
    background: var(--clr-accent2);
    display: flex;
    justify-content: flex-end;
    padding-right: 1em;
    z-index: 50;
}

.logo {
    color: var(--clr-accent);
    position: fixed;
    left: 0;
    padding: 0.75em;
    z-index: 51;
}

.burger-menu {
    height: 100%;
    width: 3em;
    padding: 0.75em 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    z-index: 51;
}

.burger-bar {
    width: 3em;
    height: 0.2em;
    background-color: var(--clr-accent);
    border-radius: 0.5em;
}

.menu {
    width: 100%;
    height: 100vh;
    color: var(--clr-accent);
    background-color: var(--clr-accent2);
    position: fixed;
    display: flex;
    top: 0;
    transition: translate 0.5s;
    translate: 100%;
    z-index: 20;
}

.nav__list {
    list-style: none;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 0;
}

.nav__link {
    color: var(--clr-accent);
    font-weight: var(--fw-bold);
    font-size: var(--fs-h3);
    text-decoration: none;
    z-index: 100;
}

/* menu animation */
.menu.visible {
    translate: 0;
}


/* burger animation */

/* clicked */
.burger-bar.clicked:nth-child(1){
    transform: rotate(45deg) translate(0.5em, 0.5em);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

.burger-bar.clicked:nth-child(2){
    transform: scale(0.1);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

.burger-bar.clicked:nth-child(3){
    transform: rotate(135deg) translate(-0.35em, 0.5em);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

/* unclicked */
.burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}


@media (min-width: 600px) {
    .burger-menu {
        display: none;
    }

    .menu {
        background: none;
        font-size: 0.3em;
        width: 70vw;
        height: 10em;
        padding: 1em;
        top: 4em;
        right: 0;
        z-index: 51;
        translate: 0;
    }

    .nav__list {
        list-style: none;
        display: flex;
        height: 1em;
        width: 100%;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        margin: 0;
        padding: 0 2em;
        gap: 3em;
    }
}