.about-me {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 7em;
    z-index: 6;
    background: radial-gradient(circle, #515151 10%, transparent 11%);
    background-size: 1em 1em;
    background-color: #353535;
    height: 100vh;
}

.titles--about {
    width: 100%;
}

.section__subtitle--about {
    width: 100%;
}

.about-me__body {
    position: relative;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    grid-area: blurb;
}

.about-me__text {
    text-align: center;
}


.cat__img {
    height: 20%;
    border-radius: 50%;
    box-shadow: var(--bs);
    margin: 2em auto;
}

@media (max-width: 599px) {
    .about-me__img {
        display: none;
    }
}

@media (min-width: 600px) {
    .about-me {
        width: 80%;
        display: grid;
        grid-template-columns: 1fr 250px;
        grid-template-areas: 
            "title img"
            "blurb img"
            "blurb auto";
        grid-column-gap: 2em;
    }

   .section__title--about {
        grid-area: title;
    }

    .section__subtitle--about {
        grid-column: 1 / -1;
        grid-row: 2;
        position: relative;
        width: calc(100% + 2em);
        padding-left: 1em;
        padding-right: calc(200px + 4em);
    }

    .about-me__body {
        align-self: start;
        width: 90%;
        margin: 0 auto;
    }

    .about-me__text {
        position: relative;
        justify-self: start;
        font-size: 0.9rem;
    }

    .about-me__img {
        display: relative;
        grid-area: img;
        position: relative;
        z-index: 2;
    }

    .cat__img {
        margin: 0.5em auto;
    }
}