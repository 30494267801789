.footer {
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: #111;
    color: var(--clr-accent);
    text-align: center;
    padding: 1.1em 0;
    font-size: var(--fs-h3);
    z-index: 19;
}

.footer a {
    color: inherit;
    text-decoration: none;
}

.footer__link {
    font-weight: var(--fw-bold);
}

.footer__link:hover,
.social-list__link:hover {
    opacity: .7;
}

.footer__link:hover {
    text-decoration: underline;
}

.social-list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0.5em 0 0;
    padding: 0;
}

.social-list__items {
    margin: 0 .5em;
}

.social-list__link {
    padding: .5em;
}

@media (min-width: 600px) {

}