*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --ff-primary: 'Biryani', sans-serif;
    --ff-secondary: 'Roboto', sans-serif;

    --fw-reg: 300;
    --fw-bold: 900;

    --clr-light: #EEEEEE;
    --clr-grey: #AAAAAA;
    --clr-dark: #212121;
    --clr-accent: #14FFEC;
    --clr-accent2: #0D7377;

    --fs-h1: 3rem;
    --fs-h2: 2.25rem;
    --fs-h3: 1.25rem;
    --fs-body: 1rem;

    --bs: 0.25em 0.25em 0.75em rgba(0,0,0,.25),
          0.125em 0.125em 0.25em rgba(0,0,0,.15);
}

@media (min-width: 800px) {
    :root {
        --fs-h1: 4rem;
        --fs-h2: 3.5rem;
        --fs-h3: 1.5rem;
        --fs-body: 1.125rem;
    }
}


/* General styles */
body {
    /* background: var(--clr-dark); */
    background: radial-gradient(circle, #515151 10%, transparent 11%);
    background-size: 1em 1em;
    background-color: #353535;
    opacity: 1;
    color: var(--clr-light);
    margin: 0;
    font-family: var(--ff-primary);
    font-size: var(--fs-body);
    line-height: 1.6;
}



section {
    padding: 5em 2em;
    height: 100vh;
}

img {
    display: block;
    max-width: 100%;
}

strong {
    font-weight: var(--fw-bold);
    color: var(--clr-accent);
}


/* Buttons */

.btn {
    display: inline-block;
    padding: 1.2em 2.5em 1em 2.5em;
    background: var(--clr-accent);
    color: var(--clr-dark);
    text-decoration: none;
    cursor: pointer;
    font-size: .8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: var(--fw-bold);
    transition: transform 200ms ease-in-out;
    border: none;
    border-radius: 0.5em;
}

.btn:hover {
    transform: scale(1.05);
}


/* Typography */

h1,
h2,
h3 {
    line-height: 1;
    margin: 0;
}

h1 {font-size: var(--fs-h1)}
h2 {font-size: var(--fs-h2)}
h3 {font-size: var(--fs-h3)}


.section__title {
    margin-bottom: .25em;
}

.section__subtitle {
    margin: 0;
    font-size: var(--fs-h3);
}

.section__subtitle--intro,
.section__subtitle--about,
.section__subtitle--work {
    /* display: flex; */
    padding: 0.25em;
    color: var(--clr-dark);
    background: var(--clr-accent);
    font-family: var(--ff-secondary);
    margin-bottom: 1em;
}