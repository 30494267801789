.intro {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    height: 100vh;
    background: radial-gradient(circle, #515151 10%, transparent 11%);
    background-size: 1em 1em;
    background-color: #353535;
}

.intro__titles {
    padding: auto;
}

.section__title--intro {
    font-weight: var(--fw-reg);
}

.section__title--intro strong {
    display: block;
}

.intro__text {
    font-size: 1.2rem;
    width: 60vw;
    text-align: center;
}

.intro__img {
    box-shadow: var(--bs);
    width: 70vw;
    min-width: 250px;
}

.intro__img-square {
    box-shadow: var(--bs);
    width: 70vw;
    min-width: 250px;
    border-radius: 50%;
}

@media (max-width: 599px) {
    .intro__img {
        display: none;
    }
}


@media (min-width: 600px) and (orientation: landscape) {

    .intro {
        display: grid;
        width: min-content;
        padding-top: 20vh;
        margin: 0 auto;
        grid-column-gap: 1em;
        grid-template-areas: 
            "img title"
            "img blurb"
            "img auto";
        grid-template-columns: min-content max-content;
    }

    .intro__text {
        grid-area: blurb;
        align-self: start;
        position: relative;
        top: -1em;
        grid-column: 0 / 1;
        width: 40vw;
        margin: 0 5vw;
    }

    .intro__img {
        align-self: start;
        grid-area: img;
        z-index: 2;
        max-width: 250px;
    }

    .intro__img-square {
        display: none;
    }

    .section__subtitle--intro {
        align-self: start;
        grid-column: -1 / 1;
        grid-row: 2;
        text-align: right;
        position: relative;
        left: -1.5em;
        width: calc(100% + 1.5em);
    }
}