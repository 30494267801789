.contact {
    display: flex;
    background: radial-gradient(circle, #353535 10%, transparent 11%);
    background-size: 1em 1em;
    background-color: #181818;
    position: relative;
    margin: 2em auto auto auto;
    z-index: 8;
    height: 77vh;
    width: 100vw;
}

.form-container {
    height: 100%;
    width: 100%;
    max-width: 750px;
}

.form {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
}

.form-heading {
    margin-bottom: 1em;
}

.fields {
    height: 60%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    gap: 1.5em;
}

.form-label {
    color: var(--clr-grey)
}

.form-field {
    width: 100%;
    color: var(--clr-light);
    background: var(--clr-dark);
    border: 0.1rem solid var(--clr-accent2);
    border-radius: 0.5em;
    padding: 0.8em;
    transition: color 0.5s ease-in-out;
}

.form-field:focus {
    border-color: var(--clr-accent);
}

.form-field__message {
    height: 12em;
}

.form-submit {
    margin-top: 1.5em;
}


@media (min-width: 600px) {
    .contact {
        height: 80vh;
        justify-content: center;
    }

    .fields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }

    .form-heading {
        margin-bottom: 0.5em;
    }

    .name-input {
        width: 48%;
    }

    .message-input {
        width: 100%;
    }

    .email-input {
        width: 48%;
        align-self: right;
    }

    .form-submit {
        width: 100%;
        margin-top: 0.5em;
    }

    .form-field__message {
        height: 8em;
    }
}